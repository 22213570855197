export const news = {
    1: {
    id: 1,
        title: '3D 區塊鏈遊戲 Idle Mystic 將於 8 月 26 日上線，已開啓空投活動',
        createTime: '2021/08/18 19:30',
        info: '鏈聞消息，3D 區塊鏈遊戲 Idle Mystic 宣佈將於 8 月 26 日上線安卓與 Windows 客戶端，目前已開啓空投活動。團隊表示，此次空投活動截止 8 月 19 日，將發放 6000 個總價值 50 萬美元的 NFT。Idle Mystic 是一款放置型 RPG 類遊戲...',
        body: '鏈聞消息，3D 區塊鏈遊戲 Idle Mystic 宣佈將於 8 月 26 日上線安卓與 Windows 客戶端，目前已開啓空投活動。團隊表示，此次空投活動截止 8 月 19 日，將發放 6000 個總價值 50 萬美元的 NFT。Idle Mystic 是一款放置型 RPG 類遊戲。'
    },
    2: {
        id: 2,
        title: '三分鐘瞭解 Idle Mystic：新款 3D NFT 策略鏈遊',
        author: 'Funky',
        createTime: '2021/08/26 12:01',
        info: '今年盛夏，GameFi 鏈游龍頭 Axie Infinity 累計交易量突破 10 億美元，創下歷史新高，超越年初火爆出圈、一直雄踞 NFT 交易額榜首的 NBA Top Shot。Axie Infinity 效應讓 GameFi 的投資創業和市場大幅增長：鏈遊板塊用戶數量截至 7 月 31 日超過 78 萬，比年初增長超過 170 倍，3 至 7 月 GameFi 日活躍錢包數量增長 700%...',
        body:
            `
                <p>今年盛夏，GameFi 鏈游龍頭 Axie Infinity 累計交易量突破 10 億美元，創下歷史新高，超越年初火爆出圈、一直雄踞 NFT 交易額榜首的 NBA Top Shot。Axie Infinity 效應讓 GameFi 的投資創業和市場大幅增長：鏈遊板塊用戶數量截至 7 月 31 日超過 78 萬，比年初增長超過 170 倍，3 至 7 月 GameFi 日活躍錢包數量增長 700%。截至 7 月 30 日，鏈遊市場吸引近 10 億美元投資，而 2020 年全年爲 7200 萬美元。各種 GameFi 鏈遊項目如雨後春筍般湧現，令人目不暇接。</p>
                <p>3D NFT 區塊鏈遊戲 Idle Mystic 提供了全新的體驗和玩法。Idle Mystic 是一款多玩家策略遊戲，融合增量和回合制的玩法，結合休閒和戰鬥模式，塑成了經典的回合制遊戲模式。在戰鬥開始前，玩家需要研究英雄（Hero）的選擇和編隊。角色之間數千種以上組合陣型、策略和數字變化的方式。玩家可以通過合理的陣型選擇和出色的能力選擇，擊敗對手並獲得獎勵。</p>
                <p>Idle Mystic 需要玩家至少擁有 3 個英雄才能開始一場戰鬥。作爲 GameFi 鏈遊的重要特色，遊戲中的英雄在鏈上被鑄造時即具有不同的技能和屬性，成爲具有遊戲和收藏價值的獨特 NFT 資產。並且 2 個英雄可以通過「繼承」（類似於繁殖）來培養下一代的英雄，並可加入收藏和戰鬥隊列。而每個英雄都有 7 次繼承的機會，屬性和能力的組合決定了新英雄的實力和稀缺性。</p>
                <p>玩家在 8 月 26 日前可以通過官方商店購入鑰匙開啓英雄（開啓的英雄被稱爲創世英雄），或者當官方交易市場上線後從市場上的其他玩家那裏購買英雄。每個創世英雄具有特殊的屬性和不能被「繼承」的傳奇技能。只有官方銷售的鑰匙才能開出創世英雄，創世英雄未來將不再鑄造。</p>
                <p>和其他傳統遊戲和 GameFi 鏈遊相比，Idle Mystic 具有以下特色：</p>
                <ul>
                    <li>- 策略對決：玩家需要根據對戰機制和不同的能力選擇不同的陣型；</li>
                    <li>- 競爭性遊戲：世界各地的玩家可以通過 PVP 模式擊敗其他玩家來獲得獎勵；</li>
                    <li>- 活躍獎勵：根據日常活動的統計，得分最高的玩家將在每個賽季結束後獲得獎勵；</li>
                    <li>- NFT 數字資產：與傳統遊戲相比，遊戲玩家能夠擁有可收藏和交易的數字資產；</li>
                    <li>- UGC 社區：玩家可通過他們的創作獲得獎勵，而社區將通過玩家的努力貢獻而繁榮。</li>
                </ul>
                <p>爲此，Idle Mystic 設計了遊戲內代幣爲」魔石」（Magic Stone），其數量是基於玩家遊戲中獲取的數量而定，玩家可以通過 PVP 獲勝和活動而獲得。但隨着英雄的「繼承」儀式的增加，魔石可能會出現短缺或雙重通縮的情況。目前，有以下 3 種方法可以獲得魔石：</p>
                <ul>
                    <li>- 運營活動：對在遊戲表現活躍突出的玩家進行獎勵。</li>
                    <li>- 賽季獎勵：根據 PVP 積分和用戶累計活躍度，在賽季結束時對排名靠前的玩家進行獎勵；</li>
                    <li>- 社區 UGC 創作獎勵：對於 UGC 內容的創作發佈者，官方將從獎池中發放魔法石作爲獎勵。</li>
                </ul>
                <p>Idle Mystic 除了讓玩家使用獨立的 NFT 資產「邊玩邊賺」之外，還通過治理代幣 IMT （Idle Mystic Token）推動 Idle Mystic 社區和生態持久的發展繁榮。玩家通過遊戲或購買獲得 IMT，可參與投票對 Idle Mystic 開展社區治理。</p>
                <p>IMT 具有項目分紅機制，官方計劃將 NFT 銷售、NFT 市場交易費和英雄繼承費等途徑的收入的 50% 用於分紅。這將由社區來投票將決定何時以及與哪些 IMT 持有者分享。官方還計劃讓擁有一定比例 IMT 的玩家成爲社區治理的利益相關者，能夠對提案進行權重投票。</p>
                <p>不同於其他鏈遊項目，Idle Mystic 的 Dapp 遊戲已經開發完成，將於 8 月 26 日上線面向全球 NFT 和遊戲玩家進行公測。目前團隊正在開發官方市場，讓玩家可以在那裏交易他們的 NFT 資產，同時也在開發手機 App，讓玩家更容易玩上這款鏈遊。未來，Idle Mystic 將推出水晶聯盟、城堡領土等更多有趣的玩法。</p>
            `
    },
    3: {
        id: 3,
        title: 'New Stage!New Release!',
        createTime: '2021-09-16',
        info: 'Idle Mystic has been receiving high attention since it officially launched on August 26.Idle Mystic has reached 20,000 registered users at 0:00 (Singapore time) on September 15.To satisfy Idlers’ needs,we launched Marketplace.The new functions include: Buy and Sell NFT, Gift NFT and Smart Contract...',
        body: `
            <p>Idle Mystic has been receiving high attention since it officially launched on August 26.Idle Mystic has reached 20,000 registered users at 0:00 (Singapore time) on September 15.To satisfy Idlers’ needs,we launched Marketplace.The new functions include: Buy and Sell NFT, Gift NFT and Smart Contract.</p>
            <p>On September 17(Singapore time),We plan to add three new functions:</p>
            <p>1. Hero inheritance</p>
            <p>2. Magic Stone extraction</p>
            <p>3. Sign in function</p>
            <p>More actions are being updated.</p>
            <p>If you have any questions, please let us know through the form:</p>
            <p><a href="https://forms.gle/FGhXWbEJGjszcL9y9" target="_blank">https://forms.gle/FGhXWbEJGjszcL9y9</a> </p>
        `
    },
    4: {
        id: 4,
        title: 'IdleMystic亚太区负责人Henry：用户是NFT游戏最宝贵的财富',
        createTime: '2021-09-22',
        info: '3D NFT游戏IdleMystic亚太地区负责人Henry透露道，IdleMystic上线以来，大概收入数十万美元，计划将这笔收入的一部分返利给为项目生态作出突出贡献的用户，其余的将会用于维护智能合约以及游戏新玩法与新功能的开发...',
        body: `
            <div class="common-width content articleDetailContent kr-rich-text-wrapper"><p>9月2日，Odaily星球日报联合36kr、CryptoArt.AI、IdleMystic、<a class="project-link" data-id="30367" data-name="网易" data-logo="https://img.36krcdn.com/20210806/v2_cef2901dde7f474d9121c11fec2c841d_img_000" data-refer-type="2" href="https://36kr.com/projectDetails/30367" target="_blank">网易</a>区块链、An<a class="project-link" data-id="523791" data-name="IMO" data-logo="https://img.36krcdn.com/20210813/v2_7f03da48088c466cb807f69a072ced1c_img_000" data-refer-type="2" href="https://36kr.com/projectDetails/523791" target="_blank">imo</a>ca Brands、DeHorizon、APENFT基金会、Torum等深度合作伙伴共同举办第一届Odaily NFT线上加密论坛「N-POWER」。活动集结了众多业界大咖，分享NFT赛道未来布局、市场趋势和生态展望。（<a href="https://play.yunxi.tv/pages/f01237f502d84c069c3946e822da0a60?openId=oY3TsvpJXWXufv3SqosFsS_H1mEc#/">点击进入直播间/观看视频回放</a>。）</p> 
            <p>其中，3D NFT游戏IdleMystic亚太地区负责人Henry透露道，IdleMystic上线以来，大概收入数十万美元，计划将这笔收入的一部分返利给为项目生态作出突出贡献的用户，其余的将会用于维护智能合约以及游戏新玩法与新功能的开发。</p> 
            <p>此外，Henry介绍，新加坡时间9月10日左右IdleMystic将上线官方交易市场，新加坡时间9月17日，会上线NFT的传承功能，接下来计划每两周更新客户端。9月份将更新的内容，还包括优化英雄战斗表现，增加游戏的耐玩性；凸显英雄技能在战斗中的优劣差异，体现出英雄价值的差异；以UGC为核心，拓展游戏的新玩法，以更多的活动回馈用户，计划发行治理代币，奖励对生态作出突出贡献的玩家。</p> 
            <p>以下为Henry演讲全文，经Odaily星球日报编辑整理，enjoy~</p> 
            <p><img src="https://img.36krcdn.com/20210922/v2_91b619ffc8a34f4fa82d64178d8d6fe3_img_000" data-img-size-val="1920,1080"></p> 
            <p>大家好，主持人好。我是IdleMystic的亚太区负责人Henry，主要负责IdleMystic在亚洲地区的推广。感谢星球日报给了我们IdleMystic平台，用这样的方式和技术和大家相聚在此。IdleMystic这个项目，我们准备了有半年之久，最终在8月17日，一朝迸发。IdleMystic是3D游戏，我们希望用IdleMystic打响区块链游戏的又一次革命，我们想让更多的人去play to earn。在新加坡时间的8月17日，我们开始了游戏英雄，也就是我们NFT的空投，通过我们的合作伙伴渠道以及Twitter转发活动，总共两天的时间内我们空投了接近5000个NFT。</p> 
            <p>这里面有一个小插曲，我们其实只准备了2500个NFT，但是Twitter转发活动非常火爆，我们一天涨粉5000多，所以决定增加1倍的数量来奖励给转发的用户。</p> 
            <p>在接下来新加坡时间8月20日凌晨零点，我们正式开启了预售阶段，我们是呈阶梯状来展示的，从单价90美元到150美元，总共有接近万余名玩家来尝试购买我们的NFT，最终只有数百位购买成功，其中很多玩家因为以太坊的堵塞而被劝退，也有很多玩家在官网中丧失了买到我们NFT的便宜的机会。</p> 
            <p>在新加坡时间8月26日，我们<a class="project-link" data-id="187722" data-name="上线了" data-logo="https://img.36krcdn.com/20210926/v2_7ca4cc14022f46a6869585d64449a199_img_000" data-refer-type="1" href="https://www.36dianping.com/space/4210300001" target="_blank">上线了</a>游戏第一版，果断地将NFT价格调整成3000美元左右，希望以此保护我们已售出的NFT的稀缺性，但即便如此，还是没有阻挡一些后面才得知我们项目的热情粉丝花费<a class="project-link" data-id="4260438" data-name="了数" data-logo="https://img.36krcdn.com/20210422/v2_8e636ec7be434dd5bf7deebc8bed2b62_img_png" data-refer-type="2" href="https://36kr.com/projectDetails/4260438" target="_blank">了数</a>万美元来购买NFT，在这里我要向我们的热情粉丝表示感谢，感谢他们的支持。</p> 
            <p>我们的游戏上线后，目前主要的功能有两个板块，挂机与PVP模式。玩家完成每一个模式下面的每个任务，就可以获得游戏中的奖励。现阶段我们暂定每天每个用户限量只能获得150个<a class="project-link" data-id="265254" data-name="魔石" data-logo="https://img.36krcdn.com/20210810/v2_319b5812d0b745719c01a87f13bcd991_img_000" data-refer-type="2" href="https://36kr.com/projectDetails/265254" target="_blank">魔石</a>，这个魔石大家可以理解为是我们游戏中的金币。</p> 
            <p>分别介绍下两种模式：</p> 
            <p>1）挂机模式非常简单，根据<a class="project-link" data-id="188814" data-name="玩家派" data-logo="https://img.36krcdn.com/20210809/v2_77dd217946ee4989b669bb4dbd7ac20c_img_000" data-refer-type="2" href="https://36kr.com/projectDetails/188814" target="_blank">玩家派</a>出的英雄数量，来决定每一轮挂机任务的时间，例如派出三个英雄的时候，挂机时间是10个小时。派出四个英雄时，挂机时间是9个小时，依此类推，派出满阵容的六个英雄时，挂机时间仅为7个小时。每一次挂机任务的完成，玩家会获得50个魔石奖励，接下来玩家只需要再次进入游戏，手动点击，开始第二轮挂机就行了，每天的24小时中，玩家最多可以进行两次挂机任务。</p> 
            <p>2）另外一种模式是PVP模式，指玩家与玩家之间的阵容比拼。我们在开展玩家之间battle的时候，你首先需要调整好自己的阵容，将自己的阵容投放到阵容池中。当你需要去寻找对手的时候，你可以在阵容池选择另外一个玩家的阵容来进行决斗，每天一个玩家累计获得10次PVP胜利后，会<a class="project-link" data-id="95377" data-name="得到" data-logo="https://img.36krcdn.com/20210807/v2_966db147ab4646ef82349f069ce61219_img_000" data-refer-type="2" href="https://36kr.com/projectDetails/95377" target="_blank">得到</a>50个魔石的奖励。</p> 
            <p>在这里我需要向大家介绍一下魔石的使用途径。第一，魔石可以用来传承，两个NFT通过一定的合成，可以生成一个新的NFT，也就是两个英雄可以合成一个新英雄，当然两个英雄是不会消失的。第二，今后这个魔石可以去去中心化交易所进行自由交易。</p> 
            <p>到现在，我们的IdleMystic上线已经有一段时间，我们大概收入数十万美元，我们计划将这笔收入的一部分返利给为我们项目生态作出突出贡献的用户，其余的我们将会用于维护我们的智能合约以及游戏新玩法与新功能的开发。</p> 
            <p>在官网上，我们计划在新加坡时间的9月10日左右上线我们的官方交易市场，届时可以满足玩家与玩家之间自由交易、相互赠送NFT的需求。</p> 
            <p>在新加坡时间的9月17日，我们会上线NFT的传承功能，就是我刚才讲到的用两个NFT生成一个新的NFT的功能，来满足后期会有越来越多新用户进入到我们的IdleMystic求购NFT。接下来在游戏的客户端，目前我们的计划是每两周会更新一次。</p> 
            <p>在9月份将更新的内容，我们会优化英雄战斗表现，增加游戏的耐玩性；其次，我们会凸显英雄技能在战斗中的优劣差异，体现出英雄价值的差异；其三，我们以UGC为核心，拓展游戏的新玩法，以更多的活动回馈我们的用户。怎么回馈我们的用户？其实有一个更大的方面，我们计划即将发行我们的治理Token，当然在这个Token流通合法的地区，我们计划对IdleMystic生态作出突出贡献的玩家奖励治理Token。</p> 
            <p>讲了这么多，我还没有说IdleMystic发起的愿景。很简单，我们希望做一款良心区块链游戏，用良币驱逐劣币的方式净化市场，尽我们最大的努力规范我们区块链游戏，成为区块链游戏中的标杆，成为区块链游戏的标准。我们不想与市场上大部分的区块链项目走相同的路，因为我们坚信，用户才是我们最宝贵的财富，只有我们让用户收入了更多的收益、利益，我们的IdleMystic才会走得更远，我们才会发展得更成功。</p> 
            <p><a class="project-link" data-id="31884" data-name="我的" data-logo="https://img.36krcdn.com/20210909/v2_1d49199c81664bf6b53a23b91e8adf0d_img_png" data-refer-type="2" href="https://36kr.com/projectDetails/31884" target="_blank">我的</a>演讲就到这里，谢谢大家。</p> 
            <p><span style="letter-spacing: 0px;"></span>本文来自<a href="https://36kr.com/projectDetails/3968527">微信</a>公众号“Odaily”（ID:o-daily），36氪经授权发布。<a href="https://www.odaily.com/" style="letter-spacing: 0px;"></a></p></div>
        `
    },
    5: {
        id: 5,
        title: 'Rapid Growth！New Version',
        createTime: '2021-09-28',
        info: 'Idle Mystic has received more and more attention since it reached 20,000 registered players on September 15th. Nearly 30,000 players have registered at 0:00 on September 27th (Singapore time). The number of registered players grew nearly twice as fast as before.',
        body: `
            <p>Idle Mystic has received more and more attention since it reached 20,000 registered players on September 15th. Nearly 30,000 players have registered at 0:00 on September 27th (Singapore time). The number of registered players grew nearly twice as fast as before.</p>
            <p>At 8：00 am on September 28th(Singapore time), Idle Mystic is scheduled to be updated and the new version is 1.2.1. More details are as follows:</p>
            <p>1. Optimize the combat effects by 50%.</p>
            <p>2. Optimize the combat display and adjust the combat tempo.</p>
            <p>3. Word Chat and GM background silence.</p>
            <p>4. Airedrop player unfreezing turntable activity.</p>
            <p>On September 30th(Singapore time), we will open the application channel for the rebate.</p>
            <p>Application Time: September 30 — October 9(Singapore time).</p>
            <p>We will finish the first batch of the rebate before October 15(Singapore time).</p>
            <p>The second batch of the rebate will be rescheduled.</p>
            <p>More information: <a href="https://idlemystic.io" target="_blank">idlemystic.io</a> </p>
        `
    },
    6: {
        id: 6,
        title: 'Idle Mystic is Powering up on Social Media!',
        createTime: '2021/10/09 12:00',
        info: 'Over the past few weeks, we have continued to grow with your help;Idle Mystic reached 30,000 registered players on September 29th(Singapore time);50,000 registered players on October 9th(Singapore time)...',
        body: `
            <div class="ap aq ar as at fz av v">
            <figure class="zc ij gf gg paragraph-image">
            <div role="button" tabindex="0" class="zd ze ao zf v zg">
            <div class="gf gg zb">
            <img alt="" class="v gx il" src="https://miro.medium.com/max/1400/1*yZOz0Sizp83oLVpXql5ewQ.png" width="700" height="394" role="presentation" />
            </div>
            </div>
            </figure>
            <p>Over the past few weeks, we have continued to grow with your help;</p>
            <p>Idle Mystic reached 30,000 registered players on September 29th(Singapore time);</p>
            <p>50,000 registered players on October 9th(Singapore time).</p>
            <p>We grew 20K registered players in just 10 days!</p>
            <p>The number of 50,000 registered players is an important milestone for Idle Mystic, and we can’t help but dream of even more to come.</p>
            <p>Our plans for October:</p>
            <p>1. The new Game Mode — Arena.</p>
            <p>There are three stages：</p>
            <p>Battle Royale — Ladder Tournament — Extreme Battle(Final).</p>
            <p>2. Charity ETH Giveaway.</p>
            <p id="8d4f" data-selectable-paragraph="">3. IDO of IMT.</p>
            <p id="9beb" data-selectable-paragraph="">Please look forward to more updates and future releases. Check us out at;</p>
            <p>Official website: <a href="https://www.idlemystic.io/" class="eh ji" target="_blank" rel="noopener ugc nofollow">idlemystic.io</a></p>
            <p data-selectable-paragraph="">Twitter: <a href="https://twitter.com/Idle_Mystic" class="eh ji" target="_blank" rel="noopener ugc nofollow">twitter.com/Idle_Mystic</a></p>
            </div>
        `
    },
    7: {
        id: 7,
        title: 'PTE Inc. Got Investment From NYSE listed company, BTCM',
        createTime: '2021-10-19',
        info: 'The Company recently invested in PTE Inc., a blockchain gaming company. PTE Inc. specializes in developing high quality blockchain-based games, providing gamers with non-fungible tokens (NFT) as in-game rewards...',
        body: `
            <p>The Company recently invested in PTE Inc., a blockchain gaming company. PTE Inc. specializes in developing high quality blockchain-based games, providing gamers with non-fungible tokens (NFT) as in-game rewards. </p>
            <p>PTE Inc. is best known for its self-developed title, Idle Mystic, the world's first 3D NFT game, which has gained over 60,000 players since its launch in August 2021. In line with its technology-driven mandate, the Company will continue to search for investment opportunities to strengthen its leading position.</p>
            <p>
                <a target="_blank" href="https://www.btcm.group/news-detail?id=57c066b4354520e66b9c0c1282234944">
                   BIT Mining Increases Investment in Ohio Cryptocurrency Mining Site
                </a>
            </p>
        `
    }
}
